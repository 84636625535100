import React from 'react';
import PropTypes from 'prop-types';
import GroupBlock from "./GroupBlock";

function pages(obj, page, step=9) {
  return Object.keys(obj)
      .slice(step*(page-1), step*page)
      .reduce((memo, current) =>{
        memo[current] = obj[current];
        return memo;
      }, {})
}

class CustomGrid extends React.PureComponent {
  render() {
    const {lessons, page} = this.props;
    const data = pages(lessons, page);
    return (
        lessons?Object.values(data).map((lesson, index) => <GroupBlock key={index} data={lesson}/>):null
    );
  }
}

CustomGrid.propTypes = {
  page: PropTypes.number.isRequired,
};

export default CustomGrid;