import React from 'react';
import { makeStyles } from "@material-ui/styles";
import {Typography} from "@material-ui/core";
import {LessonContext} from "../lessons-context";

const useStyles = makeStyles({
  lesson: {
    flex: 1,
    display: 'flex',
    position: 'relative',
    borderBottom: '1px solid #e0e7e8',
    marginBottom: '0.35em',
    '&:last-of-type': {
      marginBottom: '0',
    },
    '&:before': {
      content: "",
      position: 'absolute',
      width: '10px',
      height: '10px',
      background: '#eb564f',
      transform: 'rotate(45deg)',
      top: '12px'
    }
  },
  roomWrapper: {
    width: '20%',
    flex: 1
  },
  subject: {
    textAlign: 'left',
    fontSize: '140%',
    flex: 5,
    color: '#003049'
  },
  room: {
    fontFamily: 'CirceGlyphs',
    fontSize: '180%',
    color: '#003049'
  },
  time: {
    fontFamily: 'CirceGlyphs',
    fontSize: '.625rem',
    textTransform: 'uppercase',
    color: '#808488',
    letterSpacing: '.5px',
    whiteSpace: 'nowrap'
  },
  current: {
    marginLeft: ".5em",
    marginRight: ".5em",
    color: '#fff',
    backgroundColor: '#eb564f'
  }
});

function Lesson(props) {
  const classes = useStyles();
  const {data, number} = props;
  let {room, subjectFull, subgroup} = data[0];
  if(room === 'УП') room = 'УПУ';
  if(data.length === 2) {
    let {room: room1, subjectFull: subjectFull1, subgroup: subgroup1} = data[1];
    if(room1 === 'УП') room1 = 'УПУ';
    room = `${room} / ${room1}`;
    subjectFull = `${subjectFull}(${subgroup}) / ${subjectFull1}(${subgroup1})`;
  }
  return <div className={classes.lesson}>
    <div className={classes.roomWrapper}>
      <Typography variant={"h5"} gutterBottom className={classes.room}>{room}</Typography>
      <LessonContext.Consumer>
        {lesson => (
            // eslint-disable-next-line eqeqeq
          <Typography variant={"body2"} gutterBottom className={`${classes.time} ${lesson.number === parseInt(number)?classes.current:""}`}>{`(${lesson.bells[number].start} - ${lesson.bells[number].end})`}</Typography>
        )}
      </LessonContext.Consumer>
    </div>
    <span className={classes.subject}>
      {subjectFull}
    </span>
  </div>
}

export default Lesson;