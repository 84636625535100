import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {Accordion, AccordionDetails, AccordionSummary, Checkbox, Grid, TextField, Typography} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const drawerWidth = 550;

const styles = theme => ({
  root: {
    display: 'flex',
    position: 'absolute'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  fullList: {
    width: 'auto',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  button: {
    color:  'transparent'
  },
    details: {
        alignItems: 'center',
    },
});

class TemporaryDrawer extends React.Component {
  state = {
    open: false,
    campus: `ОЦ`,
    course: 1,
  };

  toggleDrawer = (open) => () => {
    this.setState({ open: open });
  };

  handleChangeCampus = (event) => {
    this.setState({campus: event.target.value});
  };

  render() {

    const { classes } = this.props;

    const sideList = (
        <React.Fragment>
          <Button onClick={this.toggleDrawer(!this.state.open)}>Close</Button>
          <List>
            <ListItem key={`campus`}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="campus-simple">Корпус</InputLabel>
                <Select
                    value={this.state.campus}
                    onChange={this.handleChangeCampus}
                    inputProps={{
                      name: 'campus',
                      id: 'campus-simple',
                    }}
                >
                  <MenuItem value={`ОЦ`}>ОЦ</MenuItem>
                  <MenuItem value={`ПМК`}>ПМК</MenuItem>
                </Select>
              </FormControl>
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem key={`course`}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="course-simple">Страница</InputLabel>
                <Select
                    value={this.props.page}
                    onChange={(e) => this.props.handlerChangePage(e.target.value)}
                    inputProps={{
                      name: 'page',
                      id: 'page-simple',
                    }}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                </Select>
              </FormControl>
            </ListItem>
          </List>
          <FormControlLabel
            control={
              <Checkbox
                  checked={this.props.shortSchedule}
                  onChange={this.props.handleChangeLength('shortSchedule')}
                  value={true}
              />
            }
            label={"Принудительно установить тип"}
          />
          <List>
            <ListItem key={`type`}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="type-simple">Тип расписания</InputLabel>
                <Select
                    value={this.props.type}
                    onChange={(e) => this.props.handlerChangeType(e.target.value)}
                    inputProps={{
                      name: 'type',
                      id: 'type-simple',
                    }}
                >
                  <MenuItem value={0}>Сокращенное</MenuItem>
                  <MenuItem value={1}>Стандартное</MenuItem>
                </Select>
              </FormControl>
            </ListItem>
          </List>
          <Accordion>
              <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header">
                  <Typography>Настройка полного расписания</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                  {Object.entries(this.props.bellsSchedule).map(([key, value])=> {return (
                      <Grid conteiner key={key} spacing={1} direction="row" alignItems="center">
                          <Grid xs={4}>
                              <Typography>
                                  ${key}
                              </Typography>
                          </Grid>
                          <Grid xs={4}>
                              <TextField id="start[]" label="Старт" value={value.start} />
                          </Grid>
                          <Grid xs={4}>
                              <TextField id="end[]" label="Окончание" value={value.end} />
                          </Grid>
                      </Grid>
                  )})}
              </AccordionDetails>
          </Accordion>
          <Accordion>
              <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header">
                  <Typography>Настройка сокращенного расписания</Typography>
              </AccordionSummary>
              <AccordionDetails>

              </AccordionDetails>
          </Accordion>
        </React.Fragment>
    );

    return (
        <div className={classes.root}>
          <Button className={classes.button} onClick={this.toggleDrawer(!this.state.open)}>Open Left</Button>
          <Drawer
              className={classes.drawer}
              variant="persistent"
              anchor="left"
              open={this.state.open}
              onClose={this.toggleDrawer(false)}
              classes={{
                paper: classes.drawerPaper,
              }}
          >
            {sideList}
          </Drawer>
        </div>
    );
  }
}

TemporaryDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TemporaryDrawer);