import React, {PureComponent} from 'react';
import TemporaryDrawer from "./components/Drawer";

import 'typeface-roboto';
import './App.css';
import CustomGrid from "./components/CustomGrid";
import _ from 'lodash';
import {LessonContext} from "./lessons-context";

const Moment = require('moment');
const MomentRange = require('moment-range');
const moment = MomentRange.extendMoment(Moment);

const lessons = {
  1: {start: '08:30', end: '10:00'},
  2: {start: '10:10', end: '11:40'},
  3: {start: '11:50', end: '13:20'},
  4: {start: '13:30', end: '15:00'},
  5: {start: '15:10', end: '16:40'},
  6: {start: '16:50', end: '18:20'},
  7: {start: '18:30', end: '19:50'}
};

const lessonsShort = {
  1: {start: '08:30', end: '09:30'},
  2: {start: '09:30', end: '10:40'},
  3: {start: '10:40', end: '11:50'},
  4: {start: '11:50', end: '13:00'},
  5: {start: '13:00', end: '14:40'},
  6: {start: '14:40', end: '16:10'},
  7: {start: '16:10', end: '17:50'},
};

const getOutputData = (number, raw) => {
  const results = _.orderBy(raw.filter((e) => {
    return parseInt(e.number) === parseInt(number) || parseInt(e.number) === parseInt(number) + 1;
  }), ['course', 'group']);

  return results.reduce((acc, item) => ({
    ...acc,
    [item.group]: {
      ...acc[item.group],
      group: item.group,
      course: item.course,
      [item.number]: [
        ...((acc[item.group] && acc[item.group][item.number]) || []),
        item,
      ]
    }
  }), {});
};

class App extends PureComponent {
  state = {
    result: [],
    page: 1,
    number: 1,
    time: new Date().toLocaleString(),
    type: 1,
    shortSchedule: false,
    bellsSchedule: JSON.parse(localStorage.getItem('lessons')) || lessons,
    bellsScheduleShort: JSON.parse(localStorage.getItem('lessonsShort')) || lessonsShort
  };

  tick() {
    this.setState({
      time: new Date().toLocaleString()
    });
  }

  async getSchedule(){

    await fetch('/api/schedule')
        .then(response => response.json())
        .then(state => !this.state.shortSchedule?this.setState(state):this.setState({result: state.result}))
        .then(()=>this.getLessonNumber())
        .then(()=>this.tick());
  }

  getLessonNumber() {
    let {number: lessonNumber, type} = this.state;
    let now = moment();

    const data = type===0?this.state.bellsScheduleShort:this.state.bellsSchedule;
    let timeRangeAM = moment().range(moment('00:00', 'HH:mm Z'), moment(data[1].start, 'HH:mm Z'));
    let timeRangePM = moment().range(moment(data[7].end, 'HH:mm Z'), moment('23:59', 'HH:mm Z'));
    if(now.within(timeRangeAM)) {
      lessonNumber = 1;
    } else {
      if(now.within(timeRangePM)) {
        lessonNumber = 7;
      } else {
        for (let number in data) {
          const {start, end} = data[number];
          let timeRange = moment().range(moment(start, 'HH:mm Z').subtract(10, 'm'), moment(end, 'HH:mm Z'));
          //console.table(timeRange);
          lessonNumber = now.within(timeRange) ? number : lessonNumber;
          //console.log(lessonNumber);
        }
      }
    }

    this.setState({number: parseInt(lessonNumber)});
  };

  componentDidMount() {
    this.getSchedule();
    this.timer = setInterval(()=> this.getSchedule(), 60000)
    localStorage.setItem('lessons', JSON.stringify(this.state.bellsSchedule));
    localStorage.setItem('lessonsShort', JSON.stringify(this.state.bellsScheduleShort));
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  handlerChangePage = page => {
    this.setState({page})
  };

  handleChangeLength = name => event => {
    this.setState({ ...this.state, [name]: event.target.checked });
  };

  handlerChangeType = type => {
    this.setState({type});
    this.getSchedule();
  };


  render() {
    const { result, number, type } = this.state;
    return (
        <div className="App">
          <LessonContext.Provider value={{number, type, bells: type===0?this.state.bellsScheduleShort:this.state.bellsSchedule}}>
            <TemporaryDrawer
                className="Drawer"
                page={this.state.page}
                handlerChangePage={this.handlerChangePage}
                type={this.state.type}
                shortSchedule={this.state.shortSchedule}
                bellsSchedule={this.state.bellsSchedule}
                bellsScheduleShort={this.state.bellsScheduleShort}
                handleChangeLength={this.handleChangeLength}
                handlerChangeType={this.handlerChangeType}
            />
            <CustomGrid className="Grid" lessons={getOutputData(number, result)} page={this.state.page} currentNumber={number}/>
          </LessonContext.Provider>
        </div>
    );
  }
}

export default App;
