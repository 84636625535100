import React from 'react';
import PropTypes from 'prop-types';
import {  withStyles } from '@material-ui/styles';
import {Typography} from "@material-ui/core";
import _ from 'lodash';
import Lesson from "./Lesson";

const styles  = theme => ({
  item: {
    flexGrow: 0,
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column',
    flexFlow: 'row wrap',
    height: `calc( 100vh / 3 - 1px)`,
    width: `calc( 100vw / 3 - 1px)`,
    border: `1px solid #e0e7e8`,
    borderLeft: `none`,
    borderTop: `none`
  },
  group: props => ({
    fontFamily: 'CirceGlyphs',
    borderBottom: '1px solid #e0e7e8',
    // eslint-disable-next-line eqeqeq
    background: (props.data.course == 1?
        `var(--yellow)`:
        // eslint-disable-next-line eqeqeq
        (props.data.course == 4?
            `var(--red)`:
            // eslint-disable-next-line eqeqeq
            (props.data.course == 3?
                `var(--orange)`:
                `var(--green)`))),
    color: '#fff',
    textShadow: '4px 4px 4px #000',
    webkitTextStroke: '4px #000',
    fontSize: '3rem'
  })
});

class GroupBlock extends React.PureComponent {
  render() {
    const {data, classes} = this.props;
    const lessons = _.omit(data, ['course', 'group']);
    return (
        <div className={classes.item}>
          <Typography variant="h3" gutterBottom className={classes.group}>
            {data.group}
          </Typography>
          {Object.keys(lessons).map((elem, index)=>
            <Lesson data={lessons[elem]} number={elem} key={elem}/>
          )}
        </div>
    );
  }
}

GroupBlock.propTypes = {
  data: PropTypes.object.isRequired
};

export default withStyles(styles)(GroupBlock);